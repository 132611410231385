const depthM = {
  id: '210mm (1 rangées pedales)',
  title: '210mm (1 rangées pedales)',
}

const depthL = {
  id: '340mm (2 rangées pedales)',
  title: '340mm (2 rangées pedales)',
}

const depthXL = {
  id: '550mm (3 rangées pedales)',
  title: '550mm (3 rangées pedales)',
}

const flat436 = {
  id: 'flat436',
  title: '436mm',
  depthOptions: [
    {
      ...depthM,
      price: 70,
    },
    {
      ...depthL,
      price: 85,
    },
  ],
}

const flat536 = {
  id: 'flat536',
  title: '536mm',
  depthOptions: [
    {
      ...depthM,
      price: 80,
    },
    {
      ...depthL,
      price: 90,
    },
  ],
}

const flat586 = {
  id: 'flat586',
  title: '586mm',
  depthOptions: [
    {
      ...depthM,
      price: 90,
    },
    {
      ...depthL,
      price: 95,
    },
    {
      ...depthXL,
      price: 110,
    },
  ],
}

const flat636 = {
  id: 'flat636',
  title: '636mm',
  depthOptions: [
    {
      ...depthM,
      price: 95,
    },
    {
      ...depthL,
      price: 100,
    },
    {
      ...depthXL,
      price: 120,
    },
  ],
}

const flat686 = {
  id: 'flat686',
  title: '686mm',
  depthOptions: [
    {
      ...depthL,
      price: 105,
    },
    {
      ...depthXL,
      price: 125,
    },
  ],
}

const flat736 = {
  id: 'flat736',
  title: '736mm',
  depthOptions: [
    {
      ...depthL,
      price: 110,
    },
    {
      ...depthXL,
      price: 130,
    },
  ],
}

const flat786 = {
  id: 'flat786',
  title: '786mm',
  depthOptions: [
    {
      ...depthL,
      price: 120,
    },
    {
      ...depthXL,
      price: 140,
    },
  ],
}

const flat836 = {
  id: 'flat836',
  title: '836mm',
  depthOptions: [
    {
      ...depthL,
      price: 130,
    },
    {
      ...depthXL,
      price: 150,
    },
  ],
}

const flat886 = {
  id: 'flat886',
  title: '886mm',
  depthOptions: [
    {
      ...depthL,
      price: 140,
    },
    {
      ...depthXL,
      price: 160,
    },
  ],
}

export const sizeFlat = [
  flat436,
  flat536,
  flat586,
  flat636,
  flat686,
  flat736,
  flat786,
  flat836,
  flat886,
]

const angled436 = {
  id: 'angled436',
  title: '436mm',
  depthOptions: [
    {
      ...depthM,
      price: 90,
    },
    {
      ...depthL,
      price: 115,
    },
  ],
}

const angled536 = {
  id: 'angled536',
  title: '536mm',
  depthOptions: [
    {
      ...depthM,
      price: 100,
    },
    {
      ...depthL,
      price: 115,
    },
  ],
}

const angled586 = {
  id: 'angled586',
  title: '586mm',
  depthOptions: [
    {
      ...depthM,
      price: 110,
    },
    {
      ...depthL,
      price: 120,
    },
    {
      ...depthXL,
      price: 150,
    },
  ],
}

const angled636 = {
  id: 'angled636',
  title: '636mm',
  depthOptions: [
    {
      ...depthM,
      price: 120,
    },
    {
      ...depthL,
      price: 130,
    },
    {
      ...depthXL,
      price: 160,
    },
  ],
}

const angled686 = {
  id: 'angled686',
  title: '686mm',
  depthOptions: [
    {
      ...depthL,
      price: 140,
    },
    {
      ...depthXL,
      price: 165,
    },
  ],
}

const angled736 = {
  id: 'angled736',
  title: '736mm',
  depthOptions: [
    {
      ...depthL,
      price: 160,
    },
    {
      ...depthXL,
      price: 175,
    },
  ],
}

const angled786 = {
  id: 'angled786',
  title: '786mm',
  depthOptions: [
    {
      ...depthL,
      price: 170,
    },
    {
      ...depthXL,
      price: 185,
    },
  ],
}

const angled836 = {
  id: 'angled836',
  title: '836mm',
  depthOptions: [
    {
      ...depthL,
      price: 180,
    },
    {
      ...depthXL,
      price: 195,
    },
  ],
}

const angled886 = {
  id: 'angled886',
  title: '886mm',
  depthOptions: [
    {
      ...depthL,
      price: 185,
    },
    {
      ...depthXL,
      price: 205,
    },
  ],
}

export const sizeAngled = [
  angled436,
  angled536,
  angled586,
  angled636,
  angled686,
  angled736,
  angled786,
  angled836,
  angled886,
]

export const connectiquesOpt = [
  {
    id: 'Connectique jack classique',
    label: 'Connectique jack classique Rean w/ dishround + angled jack +10€',
    price: 10,
  },
  {
    id: 'Connectique jack lock Neutrik',
    label: 'Connectique jack lock Neutrik + angled jack +20€',
    price: 20,
  },
  {
    id: 'Connectique jack lock noire Neutrik',
    label: 'Connectique jack lock noire Neutrik + angled jack +22€',
    price: 22,
  },
  {
    id: 'Prise power cable',
    label: 'Prise power cable +7€',
    price: 7,
  },
  {
    id: 'Prise power cable + multiprise',
    label: 'Prise power cable + multiprise +15€',
    price: 15,
  },
  {
    id: 'Connetique XLR Neutrik NA3 MDF',
    label: 'Connetique XLR Neutrik NA3 MDF +10€',
    price: 10,
  },
  {
    id: 'Connetique XLR Neutrik NA3 FDM',
    label: 'Connetique XLR Neutrik NA3 FDM +10€',
    price: 10,
  },
]

export const additionalOptions = [
  {
    id: 'Logo Old Tree gold&black',
    label: 'Logo Old Tree gold&black +5€',
    price: 5,
  },
  {
    id: 'Sérigraphie personnalisé sur bois',
    label: 'Sérigraphie personnalisé sur bois (prix sur demande)',
    price: 'Sérigraphie (prix sur demande)',
  },
]
