/* eslint-disable max-len */
import React from 'react'

import {
  ListContainer, ListTitle, IncludedList, ListLine,
} from '../Cabs/styles'

const List = () => (
  <ListContainer>
    <ListTitle>Le tarif de base inclut :</ListTitle>
    <IncludedList>
      <ListLine>Une teinte du bois de la couleur de votre choix (voir liste)</ListLine>
      <ListLine>Un vernis mat ou glossy</ListLine>
      <ListLine>1 tapis velcro noir</ListLine>
      <ListLine>4 rubber foot 9mm</ListLine>
    </IncludedList>
  </ListContainer>
)

export default List
