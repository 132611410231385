import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../../Layout'
import SEO from '../../SEO'

import Pedalboards from '../../components/Pedalboards'

export const query = graphql`
  {
    file(relativePath: { eq: "musicgear/music-gear-home.jpg" }) {
      childImageSharp {
        resize {
          src
          width
          height
        }
      }
    }
  }
`

const PedalboardPage = ({ data }) => (
  <Layout>
    <SEO
      title="Pedalboard"
      keywords={[
        'pedalboard', 'pedalboards', 'flat', 'angled', 'music', 'music gear',
      ]}
      image={data.file.childImageSharp.resize}
    />
    <Pedalboards />
  </Layout>
)

PedalboardPage.propTypes = {
  data: shape({}).isRequired,
}

export default PedalboardPage
