/* eslint-disable max-len */
import capitalize from 'lodash.capitalize'
import {
  sizeAngled, sizeFlat, connectiquesOpt, additionalOptions,
} from './data'

export const initSize = pedalboard => {
  const options = pedalboard === 'flat' ? sizeFlat : sizeAngled
  const width = options[0].id
  const depth = options[0].depthOptions[0].id

  return { width, depth }
}

export const initDepthId = (pedalboard, widthId) => {
  const options = pedalboard === 'flat' ? sizeFlat : sizeAngled
  const selcetedOption = options.find(opt => opt.id === widthId)

  const { depthOptions } = selcetedOption
  return depthOptions[0].id
}

export const getWidthOption = pedalboard =>
  pedalboard === 'flat' ? sizeFlat : sizeAngled

export const getDepthOptions = (pedalboard, widthId) => {
  const options = pedalboard === 'flat' ? sizeFlat : sizeAngled
  const selcetedOption = options.find(opt => opt.id === widthId)

  return selcetedOption.depthOptions.map(opt => ({
    ...opt,
    title: `${opt.title} ${opt.price}€`,
  }))
}

const getTypePrice = (pedalboard, widthId, depthId) => {
  const options = pedalboard === 'flat' ? sizeFlat : sizeAngled
  const selcetedOption = options.find(opt => opt.id === widthId)

  const { depthOptions } = selcetedOption
  const selectedDepth = depthOptions.find(d => d.id === depthId)

  return selectedDepth.price
}

const getConnectiquePrice = connectiques =>
  (connectiques || []).reduce((acc, conId) => {
    const con = connectiquesOpt.find(c => c.id === conId)
    if (!con) {
      console.error(`Cannot find connectique with ID ${conId}`)
      return acc
    }

    return acc + con.price
  }, 0)

const getAdditionalOptPrice = additionalOpt => (additionalOpt || []).reduce(
  (acc, optId) => {
    const opt = additionalOptions.find(o => o.id === optId)

    if (!opt) {
      console.error(`Cannot find connectique with ID ${optId}`)
      return acc
    }

    const { price } = opt
    if (typeof price === 'number') {
      return { ...acc, amount: acc.amount + price }
    }

    return { ...acc, text: [...acc.text, price] }
  }, { amount: 0, text: [] },
)

export const countTotal = ({
  pedalboard, width, depth, connectiques, additionalOpt, biColor,
}) => {
  if (!pedalboard || !width || !depth) return null

  const typePrice = getTypePrice(pedalboard, width, depth)
  const connectiquesPrice = getConnectiquePrice(connectiques)
  const additionalOptPrice = getAdditionalOptPrice(additionalOpt)
  const colorPrice = biColor ? 50 : 0

  const amount =
  typePrice + connectiquesPrice + additionalOptPrice.amount + colorPrice

  return {
    amount,
    text: additionalOptPrice.text,
  }
}

export const genDataForEmailDevi = ({
  pedalboard, width, depth, connectiques = [], additionalOpt, biColor, teintes,
}) => {
  const title = `Pedalboard ${pedalboard}`
  const sizeOptions = pedalboard === 'flat' ? sizeFlat : sizeAngled
  const selcetedWidth = sizeOptions.find(opt => opt.id === width)

  const depthOptions = selcetedWidth?.depthOptions
  const selectedDepth = (depthOptions || []).find(d => d.id === depth)

  const choosenConnectiques = connectiquesOpt.filter(con => connectiques.includes(con.id))
  const choosenAdditionalOpt = additionalOptions.filter(a => additionalOpt.includes(a.id))
  const teintesNames = teintes.map(id => id.split('_').map(str => capitalize(str)).join(' '))

  const data = [
    {
      label: 'Taille',
      value: `Largeur ${selcetedWidth?.title || 'Non definit'}, profondeur ${selectedDepth?.title || 'Non definit'}, prix ${selectedDepth?.price || 'Non definit'}€`,
    },
    {
      label: 'Connectiques optionnels',
      value: choosenConnectiques.map(c => c.label).join(', ') || 'Aucune',
    },
    {
      label: 'Options supplémentaires',
      value: choosenAdditionalOpt.map(a => a.label).join(', ') || 'Aucune',
    },
    {
      label: 'Teinte bois Bi-color',
      value: biColor,
    },
    {
      label: 'Teintes',
      value: teintesNames.join(' et '),
    },
  ]

  return { title, data }
}
