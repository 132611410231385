import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { string, func } from 'prop-types'

import {
  ImagesContainer,
  Image, ImgContainer, OptionContainer, OptionName, OptionFog, TitleSection,
} from '../Cabs/styles'

const query = graphql`
  {
    pedalboardAngled: file(relativePath: {
      eq: "musicgear/pedalboard-angled.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    pedalboardFlat: file(relativePath: {
      eq: "musicgear/pedalboard-flat.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 250) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

const Images = ({ pedalboard, onChange }) => {
  const data = useStaticQuery(query)
  return (
    <>
      <TitleSection>Choisissez votre pedalboard</TitleSection>
      <ImagesContainer>
        <OptionContainer onClick={() => { onChange('angled') }}>
          <ImgContainer isSelected={pedalboard === 'angled'}>
            <Image fixed={data.pedalboardAngled.childImageSharp.fixed} />
            <OptionFog isSelected={pedalboard === 'angled'} />
          </ImgContainer>
          <OptionName isSelected={pedalboard === 'angled'}>
            Pedalboard angled
          </OptionName>
        </OptionContainer>
        <OptionContainer onClick={() => { onChange('flat') }}>
          <ImgContainer isSelected={pedalboard === 'flat'}>
            <Image fixed={data.pedalboardFlat.childImageSharp.fixed} />
            <OptionFog isSelected={pedalboard === 'flat'} />
          </ImgContainer>
          <OptionName isSelected={pedalboard === 'flat'}>
            Pedalboard flat
          </OptionName>
        </OptionContainer>
      </ImagesContainer>
    </>
  )
}

Images.defaultProps = {
  pedalboard: null,
}

Images.propTypes = {
  pedalboard: string,
  onChange: func.isRequired,
}

export default Images
