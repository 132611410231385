import React from 'react'
import { arrayOf, string, func } from 'prop-types'

import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import capitalize from 'lodash.capitalize'

import {
  ImagesCotainer, ItemContainer, ImgContainer, Name,
} from './styles'

const query = graphql`
  {
    natural_brich: file(relativePath: {eq: "teintes/natural_brich.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    walnut: file(relativePath: {eq: "teintes/walnut.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    ebony: file(relativePath: {eq: "teintes/ebony.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    black_ebony: file(relativePath: {eq: "teintes/black_ebony.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    white: file(relativePath: {eq: "teintes/white.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    yellow_oak: file(relativePath: {eq: "teintes/yellow_oak.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    orange: file(relativePath: {eq: "teintes/orange.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    red_mahogany: file(relativePath: {eq: "teintes/red_mahogany.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    soft_pink: file(relativePath: {eq: "teintes/soft_pink.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    deep_blue: file(relativePath: {eq: "teintes/deep_blue.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    purple: file(relativePath: {eq: "teintes/purple.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    green: file(relativePath: {eq: "teintes/green.jpg"}) {
      childImageSharp {
        fixed(width: 150, height: 150, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

const Teintes = ({ teintes, onClick }) => {
  const data = useStaticQuery(query)

  const images = Object.entries(data).map(([name, img]) => ({
    id: name,
    name: name.split('_').map(str => capitalize(str)).join(' '),
    fixed: img.childImageSharp.fixed,
  }))

  return (
    <ImagesCotainer>
      {
        images.map(({ id, name, fixed }) => (
          <ItemContainer
            onClick={() => { onClick(id) }}
            key={id}
          >
            <ImgContainer $isSelected={teintes.includes(id)}>
              <Img fixed={fixed} />
            </ImgContainer>
            <Name>{name}</Name>
          </ItemContainer>
        ))
      }
    </ImagesCotainer>
  )
}

Teintes.propTypes = {
  teintes: arrayOf(string).isRequired,
  onClick: func.isRequired,
}

export default Teintes
