/* eslint-disable max-len */
/* eslint-disable react/state-in-constructor */
import React from 'react'

import Images from './Images'
import List from './List'
import Checkbox from '../Checkbox'
import PricePreviw from '../Cabs/PricePreviw'
import Teintes from '../Cabs/Teintes'
import Form, {
  Dialog,
  genDevis,
} from '../Form'
import PageImage from '../PageImage'
import StyledLin from '../StyledLink'
import Button from '../Button'

import {
  Content,
  Description,
  DevisTitle,
  TitleSection,
  Section,
  CheckBoxWrapper,
} from '../Cabs/styles'

import { Footer } from '../Hardcase/styles'

import { SelectContainer, SelectTitle, Select } from './style'

import { description } from '../Cabs/data'
import { connectiquesOpt, additionalOptions } from './data'

import {
  initSize,
  initDepthId,
  getWidthOption,
  getDepthOptions,
  countTotal,
  genDataForEmailDevi,
} from './helpers'

class Pedalboards extends React.Component {
  state = {
    pedalboard: null, // [angled, flat]
    width: null,
    depth: null,
    connectiques: [],
    additionalOpt: [],
    biColor: false,
    teintes: [],
    showForm: false,
  }

  onToggleForm = () => {
    const { showForm } = this.state
    this.setState({ showForm: !showForm })
  }

  onChangePedalboard = pedalboard => {
    const { width, depth } = initSize(pedalboard)
    this.setState({
      pedalboard,
      width,
      depth,
      connectiques: [],
    })
  }

  onChangeWidth = e => {
    const id = e.target.value
    const { pedalboard } = this.state
    const newDepthId = initDepthId(pedalboard, id)

    this.setState({
      width: id,
      depth: newDepthId,
    })
  }

  onChangeDepth = e => {
    const id = e.target.value
    this.setState({ depth: id })
  }

  onChangeConnectiques = id => () => {
    const { connectiques } = this.state
    if (connectiques.includes(id)) {
      this.setState({ connectiques: [...connectiques].filter(c => c !== id) })
    } else {
      this.setState({ connectiques: [...connectiques, id] })
    }
  }

  onChangeAdditionalOpt = id => () => {
    const { additionalOpt } = this.state

    if (additionalOpt.includes(id)) {
      this.setState({ additionalOpt: [...additionalOpt].filter(c => c !== id) })
    } else {
      this.setState({ additionalOpt: [...additionalOpt, id] })
    }
  }

  onHandleDoubleTeints = name => {
    const { teintes } = this.state

    if (teintes.includes(name)) {
      this.setState({ teintes: teintes.filter(t => t !== name) })
    } else {
      const thereIsSpace = teintes.length < 2
      if (thereIsSpace) {
        this.setState({ teintes: [...teintes, name] })
      } else {
        this.setState({ teintes: [teintes[1], name] })
      }
    }
  }

  onHandleSingleTeint = name => {
    const { teintes } = this.state

    const isAlreadySelected = teintes.includes(name)
    if (isAlreadySelected) {
      this.setState({ teintes: [] })
    } else {
      this.setState({ teintes: [name] })
    }
  }

  onChangeTeint = name => {
    const { biColor } = this.state

    if (biColor) {
      this.onHandleDoubleTeints(name)
    } else {
      this.onHandleSingleTeint(name)
    }
  }

  onSwitchDoubleTeint = () => {
    const { biColor, teintes } = this.state

    if (biColor) {
      const newTeintes = [teintes[0]].filter(Boolean)
      this.setState({ biColor: false, teintes: newTeintes })
    } else {
      this.setState({ biColor: true })
    }
  }

  render() {
    const {
      pedalboard,
      width,
      depth,
      connectiques,
      additionalOpt,
      biColor,
      teintes,
      showForm,
    } = this.state

    const totalPrice = countTotal({
      pedalboard, width, depth, connectiques, additionalOpt, biColor,
    })
    const showPrice = totalPrice?.amount

    const devisDataForMail = genDataForEmailDevi({
      pedalboard, width, depth, connectiques, additionalOpt, biColor, teintes,
    })
    const devisAsStringForMail = genDevis(
      devisDataForMail.title,
      devisDataForMail.data,
      totalPrice?.amount,
      totalPrice?.text,
    )

    return (
      <>
        {showPrice && (
          <PricePreviw
            amount={totalPrice?.amount}
            text={totalPrice?.text}
            openForm={this.onToggleForm}
          />
        )}
        <Dialog show={showForm} onClose={this.onToggleForm}>
          <Form
            title="Envoyer le devis"
            emailSubject="Devis Music Gear"
            withDevis
            devis={devisAsStringForMail}
          />
        </Dialog>
        <PageImage page="pedalboards" />
        <Content>
          <Description>{description}</Description>
          <DevisTitle>Créez votre devis</DevisTitle>
          <List />
          <Images pedalboard={pedalboard} onChange={this.onChangePedalboard} />
          {
            pedalboard && (
              <>
                <Section>
                  <TitleSection>
                    Choisissez la taille de votre pedalboard
                  </TitleSection>
                  <SelectContainer>
                    <SelectTitle>Largeur</SelectTitle>
                    <Select
                      value={width}
                      options={getWidthOption(pedalboard)}
                      onChange={this.onChangeWidth}
                    />
                  </SelectContainer>
                  <SelectContainer>
                    <SelectTitle>Profondeur</SelectTitle>
                    <Select
                      value={depth}
                      options={getDepthOptions(pedalboard, width)}
                      onChange={this.onChangeDepth}
                    />
                  </SelectContainer>
                </Section>
                {
                  pedalboard === 'angled' && (
                    <Section>
                      <SelectTitle>Connectiques optionnels</SelectTitle>
                      {
                        connectiquesOpt.map(con => (
                          <CheckBoxWrapper key={con.id}>
                            <Checkbox
                              label={con.label}
                              checked={connectiques.includes(con.id)}
                              onChange={this.onChangeConnectiques(con.id)}
                            />
                          </CheckBoxWrapper>
                        ))
                      }
                    </Section>
                  )
                }
                <Section>
                  <SelectTitle>Options supplémentaires</SelectTitle>
                  {
                      additionalOptions.map(opt => (
                        <CheckBoxWrapper key={opt.id}>
                          <Checkbox
                            label={opt.label}
                            checked={additionalOpt.includes(opt.id)}
                            onChange={this.onChangeAdditionalOpt(opt.id)}
                          />
                        </CheckBoxWrapper>
                      ))
                    }
                </Section>
                <Section>
                  <TitleSection>
                    Choisissez la teinte de votre Pedalboard
                  </TitleSection>
                  <CheckBoxWrapper>
                    <Checkbox
                      label="Teinte bois Bi-color +50€"
                      checked={biColor}
                      onChange={this.onSwitchDoubleTeint}
                    />
                  </CheckBoxWrapper>
                  <Teintes teintes={teintes} onClick={this.onChangeTeint} />
                </Section>
                <Section>
                  <TitleSection>
                    Besoin d’un pedalboard aux dimensions différentes de celles proposées ?
                  </TitleSection>
                  <Footer>
                    <StyledLin to="/contact">
                      <Button>Contactez-nous</Button>
                    </StyledLin>
                  </Footer>
                </Section>
              </>
            )
          }
        </Content>
      </>
    )
  }
}

export default Pedalboards
